/**
 * Add this CSS to your stylesheet or component's CSS module
 *
 * @format
 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.popover-container {
  position: relative; /* Set the reference for absolute positioning */
  display: inline-block; /* Make the container fit its content */
  z-index: 100;
}

#popover {
  position: absolute; /* Position the popover absolutely within its container */
  top: 100%; /* Position it at the bottom of the icon */
  left: 50%; /* Start at the horizontal center of the icon */
  transform: translateX(-50%); /* Adjust horizontally to center the popover */
  background-color: white; /* Background color for the popover */
  border: 1px solid #ccc; /* Optional: Border for the popover */
  border-radius: 2em; /* Optional: Rounded corners for the popover */
  padding: 1em; /* Padding inside the popover */
  z-index: 1000; /* Ensure the popover is above other content */
}

/* Optional: Add an arrow or similar visual cue */
#popover::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #ccc transparent transparent transparent;
  transform: translateX(-50%);
}

#close-button {
  text-align: end;
}
.image-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  overflow: hidden; /* Hide any content that overflows the container */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
  white-space: normal; /* Prevent text from wrapping to the next line */
  max-height: 30em;
}

.heading {
  color: ghostwhite;
  padding-top: 2em;
  padding-bottom: 1em;
  text-align: center;
  font-size: large;
}

.img {
  max-width: 100%;
  border-radius: 50%;
}

.component {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 1em;
  border-bottom: 1px solid whitesmoke;
}

.skill-bar {
  border: "1px solid black";
  height: 3px;
  width: 80%;
  border-radius: 10%;
}
.logo {
  width: 2em;
  height: auto;
  margin-right: 0.2em;
}
.navbar-container {
  background-color: rgba(0, 0, 0, 0.872);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  padding: 0.5em 1em; /* Adjust padding as needed */
  position: sticky;
  top: 0;
  z-index: 100; /* Ensure navbar is above other content */
}

.brand,
.navlinks-container {
  display: flex;
  align-items: center; /* Vertically align items */
  margin: 0.5em 0; /* Adjust margins as needed */
}

#name {
  font-weight: 600;
  color: whitesmoke;
  margin-right: 1em;
}

#nav-links ul {
  list-style-type: none;
  padding: 0;
  text-align: center; /* Center text for each link */
}
#nav-links > a,
li {
  color: white;
  margin: 0 0.5em; /* Adjust spacing between links */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

#nav-links > a:hover,
li:hover {
  color: rgb(16, 137, 117); /* Change color on hover */
}
.center-hamlinks {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: absolute; /* Position it over the navbar */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.744); /* Semi-transparent background */
}
#nav-links > a:hover {
  transform: translateY(-5%);
  transition: all 0.2s ease-in;
  text-decoration-line: underline;
  text-decoration-color: rgb(16, 137, 117);
  text-decoration-style: solid;
}

.iframe-container {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 90%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 90%;
}

#contact-details {
  color: whitesmoke;
}
#contact-details > p {
  color: white;
}
div * .signup-container {
  color: white;
}
